@font-face {
  font-family: Sharp;
  src: url("./assets/fonts/SharpSans-Semibold-BF6465806e69ce7.otf");
  font-weight: 600;
}
@font-face {
  font-family: Sharp;
  src: url("./assets/fonts/SharpSans-Bold-BF6465806eb1a52.otf");
  font-weight: 700;
}

body {
  font-family: Sharp, sans-serif;
  font-weight: 600;
  color: #525f7f;
}

a {
  cursor: pointer;
}

.App {
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-gradient {
  background: -webkit-linear-gradient(#ff00b9, #c51de8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn {
  padding: 10px 30px;
  border-radius: 30px;
  box-shadow: 0px 5px 35px 0px rgba(49, 7, 23, 0.1);
  transition: all 0.3s linear;
}

.btn-primary {
  background: #ff00b0;
  color: #fff;
  border: 1px solid #ff00b0;
}

.btn-primary:hover {
  background: #fff;
  border-color: #fff;
  color: #ff00b0;
}

.btn-secondary {
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(10px);
  border: 1px solid #fff;
}

.btn-secondary:hover {
  background: #ff00b0;
  color: #fff;
}

.btn-secondary:hover .text-gradient {
  color: #fff;
  background: unset;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.btn-buy {
  border: 1px solid #d1d4da;
  background: #fff;
  box-shadow: 0px 5px 35px 0px rgba(49, 7, 23, 0.1);
  color: #eb3c84;
}

.shadow-custom {
  box-shadow: 0px 5px 35px 0px rgba(50, 50, 93, 0.1);
}

.slider-item-custom {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.active {
  color: #ef5da8;
}
